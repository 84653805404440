import React from 'react'
import { Link } from 'gatsby'
import { RichTextElement } from '@kentico/gatsby-kontent-components'
import Icon from '../../../../assets/images/your-privacy-choices.svg'

const FooterDefault = ({ footerLinks = null, footerText = null }) => {
  return (
    <footer className="footerDefault section bg-slate">
      <div className="container">
        <div className="gap-2 grid-md-4">
          {footerLinks.map((item, index) => {
            const { name, url, links } = item
            return (
              <div className="footerDefault__item" key={index}>
                <Link to={url} className="footerDefault__title-link">
                  <h4 className="footerDefault__title">{name}</h4>
                </Link>
                <ul className="footerDefault__list">
                  {links.map((link, index) => {
                    const { name, url } = link
                    return (
                      <li className="footerDefault__list-item" key={index}>
                        {url.indexOf('https') !== -1 ? (
                          <a
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                            className="footerDefault__list-link"
                          >
                            {name}
                          </a>
                        ) : (
                          <Link to={url} className="footerDefault__list-link">
                            {name}
                          </Link>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
        {footerText && (
          <>
            <hr />
            <div className="footerDefault__footer-text">
              <a
                id="cookie-preferences"
                href="#"
                className="footerDefault__footer-btn"
                onClick={(e) => {
                  e.preventDefault()
                  window.Optanon.ToggleInfoDisplay()
                }}
              >
                Cookie Preferences
              </a>
              <Link
                className="footerDefault__footer-btn"
                href="https://privacyportal.onetrust.com/webform/962ac62d-1abf-489d-8495-ff7a33e224e7/24068fc7-e075-4f86-be67-7e0e7f31656f?src=yourheartvalve.com"
              >
                Your Privacy Choices
              </Link>
              <img src={Icon} alt="CCPA Opt-Out Icon" width="30" height="14" />

              <RichTextElement value={footerText} />
            </div>
          </>
        )}
      </div>
    </footer>
  )
}

export default FooterDefault
